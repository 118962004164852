import React, { useState } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CustomCheckbox({ value, name, label, onChange, disabled, labelFontWeightClass, id }) {

  const [checked, setChecked] = useState(value);

  const handleCheckboxChange = (event) => {
    const updatedChecked = event.target.checked;
    setChecked(updatedChecked);
    onChange(updatedChecked);
  };

  return (
    <div className="-space-y-px rounded-md mt-2">
      <label className={classNames(
          "flex items-center",
          disabled ? 'cursor-default' : 'cursor-pointer'
      )}>
        <input
          id={id}
          name={name}
          type="checkbox"
          checked={checked}
          onChange={handleCheckboxChange}
          disabled={disabled}
          className="custom-checkbox-input"
        />
        <span className={classNames(
            "ml-3 text-sm leading-6 flex flex-col text-gray-900",
            labelFontWeightClass ? labelFontWeightClass : 'font-medium')}>
          {label}
        </span>
      </label>

    </div>
  );

}